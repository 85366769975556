<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.bookName"
                  placeholder="输入书目名称"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleRevert"
                >返回</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="picture" slot-scope="{ row }">
        <el-image
          v-if="row.picture"
          style="width: 50px; height: 50px"
          :src="row.picture"
          :preview-src-list="[row.picture]"
        >
        </el-image>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <!-- <template slot="sort">
          <el-input-number
            v-model="form.sort"
            controls-position="right"
            :min="1"
            :max="99999"
            style="width:150px;"
            placeholder="输入课次排序"
          >
          </el-input-number>
        </template> -->
        <template slot="picture">
          <FormImgUpload
            :url="form.picture"
            @upload="upload"
            :limit="1"
            :maxSize="2"
            accept=".png,.jpg"
            :multiple="true"
            :disabled="false"
          />
          <!-- <div style="color:  #FF001F">
          建议尺寸：60px*60px，图片格式：.png，.jpg
        </div> -->
        </template>
        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  subjectBookList,
  subjectBookAdd,
  subjectBookEdit,
  subjectBookDelete,
} from "@/api/course/subject";
import dayjs from "dayjs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import qs from "qs";
export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      searchData: {},
      id: this.$route.query.id,
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "书目名称",
            prop: "bookName",
          },
          {
            label: "排序",
            prop: "sort",
          },
          {
            label: "配图",
            prop: "picture",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "书目名称",
            prop: "bookName",
            maxlength: 25,
            span: 16,
            row: true,
            showWordLimit: true,
            placeholder: "输入书目名称",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            type: "number",
            maxRows: 9999,
            minRows: 1,
            span: 16,
            row: true,
            append: "字",
            placeholder: "输入课次排序",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "配图",
            prop: "picture",
            type: "text",
            maxlength: 50,
            span: 24,
            // rules: [
            //   {
            //     required: true,
            //     message: "请上传",
            //     trigger: "blur",
            //   },
            //   {
            //     validator: (rule, value, callback) => {
            //       if (value.length === 0) {
            //         callback(new Error("请上传"));
            //       } else {
            //         callback();
            //       }
            //     },
            //   },
            // ],
          },
        ],
      },
      studentList: [], //学生等级下拉
      visiblePassWord: false, //密码弹窗
      newPage: this.$route.query.newPage,
    };
  },
  // created() {
  //   this.getStudent();
  // },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
        subjectId: this.id,
      };
      subjectBookList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {};
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //编辑
    async handleEdit(row) {
      this.form.id = row.id;
      this.form = {
        ...row,
        picture: row.picture ? [row.picture] : [],
      };
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        subjectBookDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.$refs.crud.toggleSelection();
            this.onLoad();
          }
        });
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form, "formformform");
          // return
          if (form.id) {
            subjectBookEdit({
              ...form,
              picture: this.form.picture ? this.form.picture.join() : "",
              subjectId: this.id,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            subjectBookAdd({
              ...form,
              picture: this.form.picture ? this.form.picture.join() : "",
              subjectId: this.id,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    recert() {
      this.visiblePassWord = false;
    },

    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.picture = [...e];
      } else {
        this.form.picture = [...this.form.picture, e];
      }
    },

    //返回
    handleRevert() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/course/subject",
        query: {
          newPage: this.newPage,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_item {
  margin-bottom: 10px;
  width: 30%;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  .table_label {
    width: 100px;
  }
}
.table_input {
  margin-right: 10px;
}
.table_add {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
